import { ApiResponse } from "@ctv/core/api/types";
import { http, HttpResponseResolver } from "msw";

type Data<T> = {
  data: T;
};
export function generateMswMock<Response = unknown, Request = unknown>(
  url: string,
  resolver: HttpResponseResolver<any, Data<Request>, ApiResponse<Response>>
) {
  return http.post<any, Data<Request>, ApiResponse<Response>, any>(
    url,
    resolver
  );
}
