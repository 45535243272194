"use client";
import config from "@ctv/core/config";
import { Suspense, use } from "react";

const mockingEnabledPromise =
  typeof window !== "undefined" &&
  config.mock.enabled &&
  !isJestCurrentlyRunning()
    ? import("../../../mocks/browser").then(async ({ worker }) => {
        await worker.start({
          serviceWorker: {
            url: "/mockServiceWorker.js",
          },
          onUnhandledRequest: "bypass",
        });
      })
    : Promise.resolve();

export function MSWProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <Suspense fallback={null}>
      <MSWProviderWrapper>{children}</MSWProviderWrapper>
    </Suspense>
  );
}

function MSWProviderWrapper({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  use(mockingEnabledPromise);

  return children;
}

function isJestCurrentlyRunning() {
  try {
    return process.env.JEST_WORKER_ID !== undefined;
  } catch (error) {
    return false;
  }
}
