import useClientApi from "@ctv/core/api/useClientApi";
import { JavaCurrencyValue } from "@ctv/shared-core/src";
import { CreditLimitStatus } from "./types";

const REMAINING_CREDIT_LIMIT_API = "/api/v1/creditlimit/get";

export type CreditLimitResponse = {
  remainingCreditLimit: {
    amount: Nullable<JavaCurrencyValue>;
    status: CreditLimitStatus;
  };
  totalCreditLimit: Nullable<{
    amount: JavaCurrencyValue;
  }>;
  remainingPercentage: Nullable<string>;
};

export function useGetCreditLimit() {
  return useClientApi<CreditLimitResponse>({
    domain: "booking",
    method: "post",
    path: REMAINING_CREDIT_LIMIT_API,
  });
}
