import { HttpResponse } from "msw";

import { ApiResponse } from "@ctv/core/api/types";
import { Permission } from "@ctv/core/auth/types";
import config from "@ctv/core/config";

import { generateMswMock } from "../msw/utils";

// ===== Account Status Handling
export const AUTH_SESSION_API = "/api/v2/authorization/session";

export type AuthSessionResponse = {
  employeeId: string;
  employeeFullName: string;
  permissions: Permission[];
  additionalData: string[];
  status: string;
  errorMessage: string | null;
  trackingSpec: any;
};

export const accountStatusMockHandlers = [
  generateMswMock<AuthSessionResponse>(
    `${config.apiHost.booking}id-id${AUTH_SESSION_API}`,
    async (_) => {
      return HttpResponse.json({
        status: "OK",
        data: {
          employeeId: "123456",
          employeeFullName: "John Doe",
          permissions: [Permission.INVOICE_LIST_VIEW],
          additionalData: ["", "", "", "ACTIVE"],
          status: "ACTIVE",
          errorMessage: null,
          trackingSpec: null,
        },
        trackingSpec: null,
      } as ApiResponse<any>);
    }
  ),
];
