"use client";
import { usePathname, useSearchParams } from "next/navigation";
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { View as RNView, StyleSheet, TouchableOpacity } from "react-native";

import { Fade, Portal, Token } from "@ctv/shared-core/src";

export const DEFAULT_FADE_VALUE = { component: undefined };

type FadeItem = {
  position?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  component: ReactNode;
};

const FadePortalContext = createContext<FadeItem>(DEFAULT_FADE_VALUE);

const FadePortalDispatchContext = createContext<
  Dispatch<SetStateAction<FadeItem>>
>(() => {});

export default function FadePortalProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [data, setData] = useState<FadeItem>(DEFAULT_FADE_VALUE);

  const location = usePathname();
  const params = useSearchParams();

  useEffect(() => {
    function handleScroll() {
      setData(DEFAULT_FADE_VALUE);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => setData(DEFAULT_FADE_VALUE), [params, location]);

  return (
    <FadePortalContext.Provider value={data}>
      <FadePortalDispatchContext.Provider value={setData}>
        {children}
        <Portal>
          <Fade
            style={[styles.fade, data.position]}
            visible={Boolean(data.component)}
          >
            {data.component}
          </Fade>
        </Portal>
      </FadePortalDispatchContext.Provider>
    </FadePortalContext.Provider>
  );
}

export function useFadePortal() {
  return useContext(FadePortalContext);
}

export function useFadePortalDispatch() {
  return useContext(FadePortalDispatchContext);
}

export function fadePortalScrollListener(
  ref: RefObject<RNView | null>,
  setFadePortal: Dispatch<SetStateAction<FadeItem>>
) {
  function handleScroll() {
    setFadePortal(DEFAULT_FADE_VALUE);
  }
  // @ts-ignore
  ref.current?.addEventListener("scroll", handleScroll);

  // To handle a warning from eslint
  const memoRef = ref.current;
  return () => {
    // @ts-ignore
    memoRef?.removeEventListener("scroll", handleScroll);
  };
}

export function handleFadePortalOpen(
  ref: RefObject<RNView | TouchableOpacity>,
  setFadePortal: Dispatch<SetStateAction<FadeItem>>,
  Component: ReactNode
) {
  ref.current?.measureInWindow((x, y) => {
    const position = { top: y + Token.spacing.xxs, left: x };
    const documentPosition = {
      top: document.documentElement.scrollTop,
      left: document.documentElement.scrollLeft,
    };

    setFadePortal({
      component: Component,
      position: {
        top: position.top + documentPosition.top,
        left: position.left + documentPosition.left,
      },
    });
  });
}

const styles = StyleSheet.create({
  fade: {
    position: "absolute",
  },
});
