"use client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

const HeaderHeightContext = createContext<
  [number | undefined, Dispatch<SetStateAction<number | undefined>>]
>(null!);

export default function HeaderHeightProvider(props: PropsWithChildren<{}>) {
  const headerHeight = useState<number | undefined>();

  return (
    <HeaderHeightContext.Provider value={headerHeight}>
      {props.children}
    </HeaderHeightContext.Provider>
  );
}

export function useHeaderHeight() {
  return useContext(HeaderHeightContext);
}
