"use client";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import useClientApi from "@ctv/core/api/useClientApi";
import { useAuth } from "@ctv/core/auth/CognitoAuthContext";
import { Permission } from "@ctv/core/auth/types";

import { AUTH_SESSION_API, AuthSessionResponse } from "./apis";
import { ModelAccountStatusContext } from "./types";

const AccountStatusContext = createContext<ModelAccountStatusContext>({
  isSuspended: false,
  isFetching: false,
  hasInvoicePermission: false,
  fetchAccountStatus: () => {},
});

export function useAccountStatus() {
  return useContext(AccountStatusContext);
}

export function AccountStatusProvider(props: PropsWithChildren<unknown>) {
  const [isFetching, setIsFetching] = useState(false);
  const [isSuspended, setIsSuspended] = useState<boolean>(false);

  const { isAuthenticated, user } = useAuth();
  const hasInvoicePermission = user?.has(Permission.INVOICE_LIST_VIEW) || false;

  const fetchAuthSession = useClientApi<AuthSessionResponse>({
    domain: "booking",
    method: "post",
    path: AUTH_SESSION_API,
  });

  const fetchAccountStatus = useCallback(
    async (callback?: () => void) => {
      setIsFetching(true);
      try {
        const res = await fetchAuthSession({});
        if (res.success) {
          const isSuspended = res.data.additionalData?.[4] === "SUSPENDED";
          setIsSuspended(isSuspended);
          if (!isSuspended && callback) {
            callback();
          }
        } else {
          console.warn(`[AccountStatusProvider]: ${res.error}`);
        }
      } catch (error) {
        console.warn("Failed to fetch account status", error);
      } finally {
        setIsFetching(false);
      }
    },
    [fetchAuthSession]
  );

  useEffect(() => {
    let refreshInterval: NodeJS.Timeout;
    if (isAuthenticated) {
      fetchAccountStatus();
      refreshInterval = setInterval(() => {
        fetchAccountStatus();
      }, 1000 * 60 * 10); //10 minutes
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [fetchAccountStatus, isAuthenticated]);

  return (
    <AccountStatusContext.Provider
      value={{
        isSuspended,
        isFetching,
        hasInvoicePermission,
        fetchAccountStatus,
      }}
    >
      {props.children}
    </AccountStatusContext.Provider>
  );
}
