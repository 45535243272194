import { HttpResponse } from "msw";

import {
  BookingFlowType,
  CreditCardType,
  PaymentMethod,
  ResponseStatus,
  useClientApi,
} from "@ctv/core";
import config from "@ctv/core/config";
import { generateMswMock } from "@ctv/core/providers/msw/utils";

const CORPORATE_SETTINGS_API = "/api/v1/corporate/setting";
type CorporateSettingsResponse = {
  bookingFlowType: BookingFlowType;
  creditCardTypes: CreditCardType[];
  paymentMethod?: PaymentMethod;
};
export function useFetchCorporateSettings() {
  return useClientApi<CorporateSettingsResponse>({
    domain: "management",
    method: "post",
    path: CORPORATE_SETTINGS_API,
  });
}

const mockUrl = `${config.apiHost.management}id-id${CORPORATE_SETTINGS_API}`;
export const coreAuthMockHandlers = [
  generateMswMock<CorporateSettingsResponse>(mockUrl, async () => {
    return HttpResponse.json({
      status: ResponseStatus.OK,
      data: {
        bookingFlowType: "TRAVEL_PERMIT",
        creditCardTypes: [
          CreditCardType.CORPORATE_CREDIT_CARD,
          CreditCardType.PERSONAL_CREDIT_CARD,
        ],
        paymentMethod: PaymentMethod.CREDIT_CARD,
      },
      trackingSpec: null,
    });
    // return HttpResponse.json({
    //     errorMessage: "Failed to retrieve data",
    //     userErrorMessage: "Failed to retrieve data",
    //     errorType: "BAD_REQUEST",
    //   } as ApiResponse<any>);
  }),
];
