import { PropsWithChildren } from "react";

import { Locale, LocaleProvider } from "@ctv/shared-core/src";
import HeaderHeightProvider from "@ctv/shared/components/Layout/HeaderHeightContext";
import FadePortalProvider from "@ctv/shared/contexts/FadePortalContext";
import { SnackbarProvider } from "@ctv/shared/contexts/SnackbarContext";

import { FeatureControlProvider } from "../feature-control/FeatureControlContext";
import { ResourceProvider, Resources } from "../resource";

import { AuthProvider } from "../auth";
import ClientInterfaceProvider from "./client-interface/ClientInterfaceContext";
import { MSWProvider } from "./msw/MSWProvider";
import QueryClientProvider from "./react-query/QueryClientProvider";
import Wrapper from "./Wrapper";

export type ProvidersProps = {
  resources: Resources;
  clientInterface: "mobile" | "desktop";
  locale: Locale;
};

export default function Providers({
  children,
  resources,
  clientInterface,
  locale,
}: PropsWithChildren<ProvidersProps>) {
  return (
    <MSWProvider>
      <LocaleProvider locale={locale}>
        <AuthProvider>
          <FeatureControlProvider>
            <ResourceProvider resources={resources}>
              <ClientInterfaceProvider clientInterface={clientInterface}>
                <QueryClientProvider>
                  <HeaderHeightProvider>
                    <SnackbarProvider>
                      <FadePortalProvider>
                        <Wrapper>{children}</Wrapper>
                      </FadePortalProvider>
                    </SnackbarProvider>
                  </HeaderHeightProvider>
                </QueryClientProvider>
              </ClientInterfaceProvider>
            </ResourceProvider>
          </FeatureControlProvider>
        </AuthProvider>
      </LocaleProvider>
    </MSWProvider>
  );
}
