"use client";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

const SnackbarContext = createContext<SnackbarType>(undefined);
const DispatchContext = createContext<Dispatch<SetStateAction<SnackbarType>>>(
  () => {}
);

type BaseSnackbar = {
  message: string;
  variant: "normal" | "alert" | "positive";
};

type ActionButton = {
  label: string;
  onClick: (callback: () => void) => void;
};

export type SnackbarType =
  | (BaseSnackbar & {
      type?: "snackbar";
    })
  | (BaseSnackbar & {
      type: "notification";
      title?: string;
      action?: [ActionButton, ActionButton?];
    })
  | undefined;

export function SnackbarProvider(props: PropsWithChildren<{}>) {
  const [message, setMessage] = useState<SnackbarType>();

  return (
    <SnackbarContext.Provider value={message}>
      <DispatchContext.Provider value={setMessage}>
        {props.children}
      </DispatchContext.Provider>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function useDispatchSnackbar() {
  return useContext(DispatchContext);
}
