"use client";
import { createContext, useContext, PropsWithChildren } from "react";

import { Resources } from "./types";

const ResourceContext = createContext<Resources>(undefined!);

export function ResourceProvider(
  props: PropsWithChildren<{ resources: Resources }>
) {
  return (
    <ResourceContext.Provider value={props.resources}>
      {props.children}
    </ResourceContext.Provider>
  );
}

export function useContentResource() {
  return useContext(ResourceContext).content;
}

export function useImageResource() {
  return useContext(ResourceContext).image;
}

export function useImageSlider() {
  return useContext(ResourceContext).slider;
}
